<template>
  <div class="practice content">
    <Tabs></Tabs>
    <!-- 进度条 -->
    <div class="content_box">
      <div class="content">
        <div class="step_box">
          <div class="step_top">
            <img src="../../../../assets/img/dizhi.png" alt="" />
            <div class="breadcrumb_box">
              <p @click="back()">每日一练</p>
            </div>
          </div>
          <div class="step_down">
            <div class="step">
              <el-progress
                :percentage="step.percentage"
                :color="step.customColor"
                :stroke-width="8"
              ></el-progress>
            </div>
            <div class="step_txt">
              已完成
              <p>{{ is_num + 1 }}</p>
              道 / 共计
              <p>{{ all_array.length }}</p>
              道
            </div>
          </div>
        </div>
      </div>
      <OneTest :data="all_array" @isNum="isNum" :type="1" @again="again" />
    </div>
  </div>
</template>

<script>
import OneTest from "../../../../components/one_test/index.vue";
import api from "@/api/api";
export default {
  components: {
    OneTest,
  },
  name: "practice",
  data() {
    return {
      step: {
        percentage: 0, //进度条
        customColor: "#409eff", // 进度条颜色值
        customColors: [
          { color: "#f56c6c", percentage: 20 },
          { color: "#e6a23c", percentage: 40 },
          { color: "#5cb87a", percentage: 60 },
          { color: "#1989fa", percentage: 80 },
          { color: "#6f7ad3", percentage: 100 },
        ], // 进度条颜色值
      },
      is_num: 0, //当前第几页
      total: 0, //总题数
      all_array: [{ qtype: 1 }],
      current_page_index: 0,
    };
  },
  created() {
    this.current_page_index = Math.floor(Math.random() * 10 + 1);
    this.getQuestion(this.current_page_index);
  },
  methods: {
    //重新生成
    again() {
      this.getTopicNumber();
      this.current_page_index = this.current_page_index + 1;
      this.getQuestion(this.current_page_index);
    },
    //当前第几页

    isNum(val) {
      // console.log(val);
      this.is_num = val;
     this.step.percentage =((Number(val) + 1) / this.all_array.length) * 100;
    },
    // 返回上一页
    back() {
      this.$router.go(-1);
    },
    //   获取问题列表
    getQuestion(page_size) {
      // // console.log(page_size);

      var data = {
        page: page_size,
        chapter_category: this.$route.params.chapter_id,
        // ex_chapter_id: this.$route.params.chapter_id,
        three_chaptercate_id: this.$route.params.three_chaptercate_id,
      };
      api.random(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          // console.log(res.data.data.list)
          let a = res.data.data.list;
          this.step.percentage =(1 / res.data.data.list.length) * 100;
          a.forEach((e) => {
            if (e.qu_list) {
              e.qu_list.forEach((c) => {
                c.user_answer = "";
              });
            } else {
              e.user_answer = "";
            }
          });
          this.all_array = a;
          this.current_page_index = res.data.data.current_page_index;
        }
        if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        }
      });
    },
    // 获取做题数上报接口
    getTopicNumber() {
      let a = 0;
      let b = 0;
      let data = [];
      this.all_array.forEach((x) => {
        // console.log(x)
        switch (x.qtype) {
          case 1:
            if (x.is_YN) {
              a++;
              data.push({
                two_chapcate_id: x.two_chaptercate_id,
                three_chapcate_id: x.chapter_category_id,
              });

              if (x.is_YN == 2) {
                b++;
              }
            }
            break;
          case 2:
            x.qu_list.forEach((c) => {
              if (x.is_YN) {
                a++;
                data.push({
                  two_chapcate_id: x.two_chaptercate_id,
                  three_chapcate_id: x.chapter_category_id,
                });

                if (c.is_YN == 2) {
                  b++;
                }
              }
            });
            break;
          case 3:
            x.qu_list.forEach((c) => {
              if (x.is_YN) {
                a++;
                data.push({
                  two_chapcate_id: x.two_chaptercate_id,
                  three_chapcate_id: x.chapter_category_id,
                });

                if (c.is_YN == 2) {
                  b++;
                }
              }
            });
            break;
          case 4:
            if (x.is_YN) {
              a++;
              data.push({
                two_chapcate_id: x.two_chaptercate_id,
                three_chapcate_id: x.chapter_category_id,
              });
              if (x.is_YN == 2) {
                b++;
              }
            }
            break;
        }
      });
      let params = [];
      data.forEach((e) => {
        (e.total_count = a), (e.err_count = b);
        if (params.length <= 0) {
          return params.push(e);
        }
        for (let i = 0; i < params.length; i++) {
          if (
            params[i].two_chapcate_id != e.two_chapcate_id &&
            params[i].three_chapcate_id != e.three_chapcate_id
          ) {
            params.push(e);
          }
        }
      });
      if (params.length > 0) {
        let a = "";
        for (let index = 0; index < params.length; index++) {
          if (a == "") {
            a = JSON.stringify(params[index]);
          } else {
            a = a + "|" + JSON.stringify(params[index]);
          }
        }
        params = a;
        api.question_count(params).then((res) => {
          // console.log("做题数", res);
        });
      }
    },
  },
  beforeDestroy() {
    // this.getTopicNumber();
  },
};
</script>

<style lang="scss" scoped>
// background: #f5f5f5;
// 内容区
.content_box {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  // padding-bottom: 300px;
  .content {
    width: 1200px;
    margin: auto;
    overflow: hidden;

    // 进度条
    .step_box {
      width: 1120px;
      height: 80px;
      background: #fff;
      margin: 16px 0 10px;
      padding: 0 40px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
      .step_top {
        display: flex;
        align-items: center;
        // height: 23px;
        // margin: 28px 0 10px;
        img {
          width: 16px;
          height: 18px;
          margin-right: 10px;
        }
        .breadcrumb_box {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          p:nth-child(1) {
            color: #666;
            font-weight: 400;
            cursor: pointer;
          }
          p:nth-child(2) {
            display: block;
            width: 416px;
            color: #222222;
            font-weight: 500;
          }
        }
      }
      .step_down {
        display: flex;
        align-items: center;
        .step {
          width: 260px;
          margin-right: 30px;
          /deep/.el-progress-bar__outer {
            width: 260px;
          }
          /deep/.el-progress__text {
            display: none;
          }
        }
        .step_txt {
          display: flex;
        }
      }
    }
  }
}
</style>